import { LEARNING_PATH_HOME_BACKGROUND } from '@common/constant/iconConstant';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const LearningPathSectionStyle = styled(Box)(
  ({ theme }) => `
  position: relative;

  height: 346px;

  ${theme.breakpoints.down(320)} {
    height: 429px;
  }

  padding-left: 16px;

  background: url(${LEARNING_PATH_HOME_BACKGROUND});
  background-size: cover;
  background-position: start;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  .react-multi-carousel-list {
    position: unset;
  }

  .container {
    position: relative;
    max-width: 1150px;

    ${theme.breakpoints.up('md')} {
      max-width: 1182px;
    }

    ${theme.breakpoints.between(1701, 1999)} {
      max-width: 1472px !important;
    }

    ${theme.breakpoints.up(2000)} {
      max-width: 1762px !important;
    }
  }
  
  ${theme.breakpoints.down('md')} {
    padding: 0 6px;
  }
  
  .carousel-item {
    padding: 0 0.5rem 0 0.5rem;
  }

  .carousel-container {
    .next-button {
      position: absolute;
      right: 80px;
      top: 50%;
      transform: translateY(-50%);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
    }

    .previous-button {
      position: absolute;
      left: 80px;
      top: 50%;
      transform: translateY(-50%);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  ${theme.breakpoints.down('sm')} {
    background-position: 20% 0%;
  }

  ${theme.breakpoints.between('md', 'lg')} {
   background-position: 20% 0%;
  }
`,
);
