import { useDispatch, useSelector } from 'react-redux';

import { LearningPathStatus } from '@common/enum/learningPath';
import { useEffectOnce } from 'react-use';

import learningPathActions from '@redux/learningPath/action';
import { RootState } from '@redux/reducer';

const { fetchLearningPathSectionItemListAction } = learningPathActions;

const useLearningPathSection = () => {
  const dispatch = useDispatch();
  const { learningPathSectionItemList } = useSelector((state: RootState) => ({
    learningPathSectionItemList: state.LearningPath.get('learningPathSectionItemList'),
  }));

  useEffectOnce(() => {
    dispatch(
      fetchLearningPathSectionItemListAction.request({
        page: 1,
        pageSize: 8,
        status: LearningPathStatus.ACTIVE,
        isDashboard: true,
      }),
    );
  });

  return { learningPathSectionItemList };
};

export default useLearningPathSection;
